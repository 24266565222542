import * as React from "react";
import Button, { ButtonVariant } from "../components/Button";
import Layout from "../components/Layout";
import Section from "../components/Section";

const Prestations = () => {
  return (
    <Layout
      title="Prestations"
      metaTitle="Nos prestations en UX Design"
      description="Découvrez nos prestations pour optimiser vos parcours utilisateurs, créer et/ou améliorer votre site web. Un accompagnement unique et personnalisé"
    >
      <p className="mt-main">
        Nous proposons des prestations adaptées à votre besoin. Vous pouvez
        sélectionner une prestation pour en savoir plus ou nous contacter
        directement pour un diagnostic personnalisé. Nous avons travaillé avec
        de nombreuses enseignes que vous retrouverez dans la section “Nos
        réalisations” du menu. N’hésitez pas à nous joindre afin d’obtenir des
        informations sur les solutions envisageables pour votre structure.
      </p>
      {/* section optimiser son parcours */}
      <Section title="📈 Besoin d'une optimisation de votre parcours ?">
        <p className="mt-8 lg:mt-10">
          Nous souhaitons accompagner nos clients sur le long terme pour leur
          permettre quotidiennement de ressentir les avantages d'une vision
          centrée utilisateur sur leur business et l'évolution de leurs
          indicateurs clés.
        </p>
        <ul className="mt-8 text-base lg:mt-10 lg:text-xl">
          <li className="my-4">🎯 Augmentez votre taux de conversion</li>
          <li className="my-4">
            🎯 Mettez la créativité au service de vos idées
          </li>
          <li className="my-4">🎯 Conception de maquettes évolutives</li>
          <li className="my-4">
            🎯 Faites des choix éclairés grâce aux tests utilisateur
          </li>
        </ul>
        <Button
          variant={ButtonVariant.secondary}
          to="/optimiser-son-parcours-utilisateur"
          text="Améliorer mon parcours utilisateur"
          className="mx-auto mt-8 lg:mt-10"
        />
      </Section>
      {/* section refonte de site */}
      <Section title="🎨 Besoin d'une refonte ?">
        <p className="mt-main">
          Nous développons une solution en adéquation avec votre stratégie
          digitale en y injectant notre expertise et notre expérience du
          domaine.
        </p>
        <ul className="mt-8 text-base lg:mt-10 lg:text-xl">
          <li className="my-4">🎯 Repenser le parcours</li>
          <li className="my-4">🎯 Éléments de réassurance</li>
          <li className="my-4">🎯 Travail des pages produits</li>
          <li className="my-4">🎯 Travail du méga menu</li>
        </ul>
        <Button
          variant={ButtonVariant.secondary}
          to="/refondre-mon-site"
          text="Refondre mon site"
          className="mx-auto mt-8 lg:mt-10"
        />
      </Section>
      {/* section creation de site */}
      <Section title="✨ Besoin d'une création ?">
        <p className="mt-main">
          Nous développons une solution en adéquation avec votre stratégie
          digitale en y injectant notre expertise et notre expérience du
          domaine.
        </p>
        <ul className="text-base mt-main lg:text-xl">
          <li className="my-4">🎯 Analyse et estimation</li>
          <li className="my-4">🎯 Benchmark et stratégie</li>
          <li className="my-4">🎯 Définition de l’identité de marque</li>
          <li className="my-4">🎯 Conception graphique</li>
          <li className="my-4">🎯 Tests utilisateur</li>
        </ul>
        <Button
          variant={ButtonVariant.secondary}
          to="/creer-mon-site"
          text="Créer mon site"
          className="mx-auto mt-main"
        />
      </Section>
      {/* section formation de site */}
      <Section title=" 🎓 Besoin d'une formation ?">
        <p className="mt-main">
          Quelques heures ou quelques jours vous permettront de monter en
          compétences et d'atteindre vos objectifs.
        </p>
        <ul className="text-base mt-main lg:text-xl">
          <li className="my-4">
            🎯 Ajoutez des compétences à forte valeur ajoutée à vos employés
          </li>
          <li className="my-4">
            🎯 Appliquez directement vos apprentissages sur votre site grâce au
            projet fil rouge
          </li>
          <li className="my-4">
            🎯 Bénéficiez d'une formation gratuite grâce aux aides auxquelles
            vous avez droit
          </li>
        </ul>
        <Button
          variant={ButtonVariant.secondary}
          to="/formations"
          text="Former mon équipe"
          className="mx-auto mt-main"
        />
      </Section>
    </Layout>
  );
};

export default Prestations;
